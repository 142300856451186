import getConfig from "next/config";

export const getAppEnvInClient = () => {
  const {
    publicRuntimeConfig: { APP_ENV },
  } = getConfig();
  return APP_ENV;
};

/**
 * Detects the current environment based on the window location
 * @returns {string} The environment name (pr-number, staging, release, production)
 */
export const detectEnvironment = () => {
  // eslint-disable-next-line no-process-env
  const hostname = typeof window !== "undefined" ? window.location.hostname : process.env.HOSTNAME || "localhost";

  // Check for PR environment
  const prMatch = hostname.match(/^pr-(\d+)\.monwbi\.cronoswallonia\.be$/);
  if (prMatch) {
    return `pull-request`;
  }

  // Check for other environments
  switch (hostname) {
    case "monwbi.cronoswallonia.be":
      return "staging";
    case "release.monwbi.cronoswallonia.be":
      return "release";
    case "mon.wbi.be":
      return "production";
    default:
      return "localhost";
  }
};
