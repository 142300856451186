import type { AbilityBuilder } from "@casl/ability";
import { APPLICATION_STEP, LIQUIDATION_STEP } from "~/constants/steps/steps";
import type { FlatAppAbility } from "~/types/permissions";
export const getAccountantPermissions = ({
  can
}: {
  can: AbilityBuilder<FlatAppAbility>["_addRule"];
}) => {
  can("read", "dossiers", "id", {
    "validations.subject": {
      $in: ["third_party_creation", "third_party_modification"]
    },
    "validations.approved": {
      $eq: false
    },
    step: APPLICATION_STEP.VERIFICATION
  });
  can("create", "dossiers", "thirdPartyCreation", {
    "validations.subject": {
      $in: ["third_party_creation", "third_party_modification"]
    },
    "validations.approved": {
      $eq: false
    },
    step: APPLICATION_STEP.VERIFICATION
  });
  can("read", "dossiers", ["id", "application_form_answers"], {
    step: {
      $in: [APPLICATION_STEP.ACTIVE, APPLICATION_STEP.CONTROL_OVERPAYMENT]
    }
  });
  can("seeBo", "dossiers");
  can(["create", "read"], "memos");
  can("read", "dossiers", "liquidation", {
    step: {
      $in: [APPLICATION_STEP.ACTIVE, APPLICATION_STEP.CLOSURE, APPLICATION_STEP.CONTROL, APPLICATION_STEP.CONTROL_VERIFICATION, APPLICATION_STEP.CONTROL_MODIFICATION, APPLICATION_STEP.CONTROL_OVERPAYMENT, APPLICATION_STEP.ARCHIVED]
    }
  });
  can("read", "dossiers", "subvention_projects");
  can("read", "liquidations", "validations");
  can("create", "liquidations", "validations", {
    step: {
      $in: [LIQUIDATION_STEP.ACCOUNTANT_VALIDATION]
    }
  });
  can("read", "dossiers", "control_files", {
    step: {
      $in: [APPLICATION_STEP.CONTROL, APPLICATION_STEP.CONTROL_MODIFICATION, APPLICATION_STEP.CONTROL_VERIFICATION, APPLICATION_STEP.CONTROL_OVERPAYMENT, APPLICATION_STEP.ARCHIVED]
    }
  });
  can("read", "dossiers", "control_requested", {
    step: {
      $in: [APPLICATION_STEP.CONTROL_OVERPAYMENT]
    }
  });
  can("read", "dossiers", "control_requested", {
    controlled: true,
    step: APPLICATION_STEP.ARCHIVED
  });
  can("read", "dossiers", "control_requested", {
    closed_early: true,
    has_overpayment: true,
    step: APPLICATION_STEP.ARCHIVED
  });
  can("create", "dossiers", "validations.payment", {
    step: APPLICATION_STEP.CONTROL_OVERPAYMENT
  });

  // PIA database
  can("read", "PIA-database");
};