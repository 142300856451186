import clsx from "clsx";
import React from "react";
import { getFontWeightClassName } from "~/utils/general";
export const Typography = {
  h1: ({
    className,
    textColor = "text-neutral-800",
    children,
    ...props
  }: React.ComponentPropsWithoutRef<"h1"> & {
    textColor?: string;
  }) => <h1 className={clsx("text-heading-1", textColor, className)} {...props}>
      {children}
    </h1>,
  h2: ({
    className,
    fontWeight = "700",
    textColor = "text-neutral-800",
    children,
    ...props
  }: React.ComponentPropsWithoutRef<"h2"> & {
    fontWeight?: "500" | "700";
    textColor?: string;
  }) => <h2 className={clsx("text-heading-2", textColor, getFontWeightClassName(fontWeight), className)} {...props}>
      {children}
    </h2>,
  h3: ({
    className,
    fontWeight = "700",
    textColor = "text-neutral-800",
    children,
    ...props
  }: React.ComponentPropsWithoutRef<"h3"> & {
    fontWeight?: "500" | "700";
    textColor?: string;
  }) => <h3 className={clsx("text-heading-3", textColor, getFontWeightClassName(fontWeight), className)} {...props}>
      {children}
    </h3>,
  h4: ({
    className,
    fontWeight = "700",
    textColor = "text-neutral-800",
    children,
    ...props
  }: React.ComponentPropsWithoutRef<"h4"> & {
    fontWeight?: "500" | "700";
    textColor?: string;
  }) => <h4 className={clsx("text-heading-4", textColor, getFontWeightClassName(fontWeight), className)} {...props}>
      {children}
    </h4>,
  body: ({
    className,
    fontWeight = "400",
    textColor = "text-neutral-800",
    children,
    ...props
  }: React.ComponentPropsWithoutRef<"p"> & {
    fontWeight?: "400" | "500" | "600" | "700";
    textColor?: string;
  }) => <p className={clsx("text-body", textColor, getFontWeightClassName(fontWeight), className)} {...props}>
      {children}
    </p>,
  note: ({
    className,
    fontWeight = "400",
    textColor = "text-inherit",
    children,
    ...props
  }: React.ComponentPropsWithoutRef<"p"> & {
    fontWeight?: "400" | "500" | "600" | "700";
    textColor?: string;
  }) => <p className={clsx("text-note", textColor, getFontWeightClassName(fontWeight), className)} {...props}>
      {children}
    </p>,
  help: ({
    className,
    fontWeight = "400",
    textColor = "text-neutral-800",
    children,
    ...props
  }: React.ComponentPropsWithoutRef<"p"> & {
    fontWeight?: "400" | "600" | "700";
    textColor?: string;
  }) => <p className={clsx("text-help", textColor, getFontWeightClassName(fontWeight), className)} {...props}>
      {children}
    </p>,
  printHeaderFooter: ({
    className,
    fontWeight = "400",
    textColor = "text-neutral-800",
    children,
    ...props
  }: React.ComponentPropsWithoutRef<"p"> & {
    fontWeight?: "400" | "600" | "700";
    textColor?: string;
  }) => <p className={clsx("text-printStylerHeaderFooter", textColor, getFontWeightClassName(fontWeight), className)} {...props}>
      {children}
    </p>,
  printText: ({
    className,
    fontWeight = "400",
    textColor = "text-neutral-800",
    children,
    ...props
  }: React.ComponentPropsWithoutRef<"p"> & {
    fontWeight?: "400" | "600" | "700";
    textColor?: string;
  }) => <p className={clsx("text-printText", textColor, getFontWeightClassName(fontWeight), className)} {...props}>
      {children}
    </p>,
  error: ({
    className,
    children,
    ...props
  }: React.ComponentPropsWithoutRef<"p">) => <p className={clsx("text-error text-left text-sm font-semibold leading-4", className)} {...props}>
      {children}
    </p>
};