import type { AbilityBuilder } from "@casl/ability";

import { APPLICATION_STEP, LIQUIDATION_STEP } from "~/constants/steps/steps";
import type { FlatAppAbility } from "~/types/permissions";

export const getTreasurerPermissions = ({ can }: { can: AbilityBuilder<FlatAppAbility>["can"] }) => {
  can("seeBo", "dossiers");
  can("read", "dossiers", ["id", "application_form_answers"], { step: { $in: [APPLICATION_STEP.ACTIVE, APPLICATION_STEP.CONTROL_OVERPAYMENT] } });
  can("read", "dossiers", "subvention_projects");
  can("read", "liquidations", "validations");
  can(["create", "read"], "memos");

  // PIA database
  //can("read", "PIA-database");

  can("read", "dossiers", "liquidation", {
    step: {
      $in: [
        APPLICATION_STEP.ACTIVE,
        APPLICATION_STEP.CLOSURE,
        APPLICATION_STEP.CONTROL,
        APPLICATION_STEP.CONTROL_VERIFICATION,
        APPLICATION_STEP.CONTROL_MODIFICATION,
        APPLICATION_STEP.CONTROL_OVERPAYMENT,
        APPLICATION_STEP.ARCHIVED,
      ],
    },
  });

  can("update", "dossiers", "validations.payment", { step: APPLICATION_STEP.ACTIVE, liquidation: { step: LIQUIDATION_STEP.PAYMENT } });
  can("update", "liquidations", "amount", { step: LIQUIDATION_STEP.PAYMENT });
};
