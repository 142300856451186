import { useSessionData } from "~/hooks/session";
import { trpc } from "~/utils/trpc";
export const useUserLocal = () => {
  const {
    data: session
  } = useSessionData();
  const utils = trpc.useUtils();
  const {
    data,
    isLoading,
    error
  } = trpc.users.byId.useQuery({
    id: session?.user.id ?? ""
  }, {
    enabled: !!session?.user.id
  });
  const updateLocalMutation = trpc.users.updateLocale.useMutation({
    onSuccess: () => {
      utils.users.invalidate();
    }
  });
  const locale = data?.locale;
  const updateLocal = async (newLocal: string) => {
    try {
      await updateLocalMutation.mutateAsync({
        locale: newLocal
      });
    } catch (err) {
      console.error("Erreur lors de la mise à jour de 'local':", err);
    }
  };
  return {
    locale,
    isLoading,
    error,
    updateLocal,
    isUpdating: updateLocalMutation.isLoading
  };
};