import { createTRPCProxyClient, httpBatchLink, loggerLink } from "@trpc/client";
import { createTRPCNext } from "@trpc/next";
import type { NextPageContext } from "next";
import getConfig from "next/config";
import superjson from "superjson";

// ℹ️ Type-only import:
// https://www.typescriptlang.org/docs/handbook/release-notes/typescript-3-8.html#type-only-imports-and-export
import type { AppRouter } from "~/server/routers/_app";
import { getAppEnvInClient } from "~/utils/environment";

/**
 * Extend `NextPageContext` with meta data that can be picked up by `responseMeta()` when server-side rendering
 */
export interface SSRContext extends NextPageContext {
  /**
   * Set HTTP Status code
   * @example
   * const utils = trpc.useContext();
   * if (utils.ssrContext) {
   *   utils.ssrContext.status = 404;
   * }
   */
  status?: number;
}
const { publicRuntimeConfig } = getConfig();

const {
  APP_URL,
  //  WS_URL
} = publicRuntimeConfig;

function getEndingLink() {
  if (typeof window === "undefined") {
    return httpBatchLink({
      url: `${APP_URL}/api/trpc`,
    });
  } else
    return httpBatchLink({
      url: "/api/trpc",
    });
  // const client = createWSClient({
  //   url: WS_URL,
  // });
  // return wsLink<AppRouter>({
  //   client,
  // });
}

const trpcConfig = {
  /**
   * @link https://trpc.io/docs/data-transformers
   */
  transformer: superjson,
  /**
   * @link https://trpc.io/docs/links
   */
  links: [
    loggerLink({
      enabled: (opts) => getAppEnvInClient().APP_ENV !== "production" || (opts.direction === "down" && opts.result instanceof Error),
    }),
    getEndingLink(),
  ],
  /**
   * @link https://react-query.tanstack.com/reference/QueryClient
   */
  // queryClientConfig: { defaultOptions: { queries: { staleTime: 60 } } },
};

/**
 * A set of strongly-typed React hooks from your `AppRouter` type signature with `createReactQueryHooks`.
 * @link https://trpc.io/docs/react#3-create-trpc-hooks
 */
export const trpc = createTRPCNext<AppRouter, SSRContext, "ExperimentalSuspense">({
  config() {
    return trpcConfig;
  },
  /**
   * @link https://trpc.io/docs/ssr
   */
  ssr: false,
});

export const trpcClient = createTRPCProxyClient<AppRouter>(trpcConfig);
