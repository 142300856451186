export const isNumber: (value: string | number) => boolean = (value) => {
  return value != null && value !== "" && !isNaN(Number(value.toString()));
};

export const getFontWeightClassName = (fontWeight: "400" | "500" | "600" | "700") => {
  return {
    "font-normal": fontWeight === "400",
    "font-medium": fontWeight === "500",
    "font-semibold": fontWeight === "600",
    "font-bold": fontWeight === "700",
  };
};

export const isJsonString = (str: string) => {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
};
