import { Ability, type AbilityClass } from "@casl/ability";

import type {
  Application_Actions_HistoryFragment,
  Contact_InfoFragment,
  DepartmentFragment,
  DispositifFragment,
  DossierFragment,
  Dossiers_GroupFragment,
  FileFragment,
  LiquidationFragment,
  MembershipFragment,
  MemoFragment,
  OrganisationFragment,
  Pdf_ConfigFragment,
  Pdf_DocumentFragment,
  Pdf_TemplateFragment,
  Reference_DispositifFragment,
  ServiceFragment,
  Sub_DepartmentFragment,
  Subvention_ProjectFragment,
  UserFragment,
  ValidationFragment,
  Values_ListFragment,
} from "@monwbi/hasura";

import type { Flatten } from "~/utils/types";

type CRUD = "create" | "read" | "update" | "delete";
export type Actions = CRUD | "seeBo" | "seeFo";

type Simplify<T> = {
  [KeyType in keyof T]: T[KeyType];
} & object;
type Subject<
  T extends {
    __typename: string;
  },
> = T | T["__typename"];
type Flat<
  T extends {
    __typename: string;
  },
> = Simplify<T & Flatten<T>>;

type MemoSubject = Subject<MemoFragment>;
type DossierSubject = Subject<DossierFragment>;
type ServiceSubject = Subject<ServiceFragment>;
type DispositifSubject = Subject<DispositifFragment>;
type ReferenceDispositifSubject = Subject<Reference_DispositifFragment>;
type UserSubject = Subject<UserFragment>;
type Dossiers_GroupSubject = Subject<Dossiers_GroupFragment>;
type Application_Actions_HistorySubject = Subject<Application_Actions_HistoryFragment>;
type FileSubject = Subject<FileFragment>;
type LiquidationSubject = Subject<LiquidationFragment>;
type SubventionProjectSubject = Subject<Subvention_ProjectFragment>;
type PdfTemplateSubject = Subject<Pdf_TemplateFragment>;
type PdfConfigSubject = Subject<Pdf_ConfigFragment>;
type PdfDocumentSubject = Subject<Pdf_DocumentFragment>;
type ContactInfoSubject = Subject<Contact_InfoFragment>;
type ValuesListSubject = Subject<Values_ListFragment>;
type OrganisationsSubject = Subject<OrganisationFragment>;
type SubDepartmentSubject = Subject<Sub_DepartmentFragment>;
type DepartmentSubject = Subject<DepartmentFragment>;
type ValidationsSubject = Subject<ValidationFragment>;
type MembershipsSubject = Subject<MembershipFragment>;

export type Subjects =
  | DossierSubject
  | MemoSubject
  | ServiceSubject
  | DispositifSubject
  | ReferenceDispositifSubject
  | UserSubject
  | Dossiers_GroupSubject
  | Application_Actions_HistorySubject
  | FileSubject
  | LiquidationSubject
  | SubventionProjectSubject
  | PdfTemplateSubject
  | PdfConfigSubject
  | PdfDocumentSubject
  | ContactInfoSubject
  | ValuesListSubject
  | SubDepartmentSubject
  | DepartmentSubject
  | OrganisationsSubject
  | ValidationsSubject
  | MembershipsSubject
  | "User"
  | "Experiment"
  | "Faq"
  | "Repository"
  | "keycloak-users"
  | "PIA-database"
  | "all";

export type AppAbility = Ability<[Actions, Subjects]>;
export const AppAbility = Ability as AbilityClass<AppAbility>;

type FlatDossierSubject = Subject<DossierFragment>;

// This allows to make nested condition work with typescript (e.g. "utilisateurs_wbi.id")
type FlatSubjects =
  | FlatDossierSubject
  | Subject<Flat<MemoFragment>>
  | Subject<Flat<ServiceFragment>>
  | Subject<Flat<DispositifFragment>>
  | Subject<Flat<Reference_DispositifFragment>>
  | Subject<Flat<UserFragment>>
  | Subject<Flat<Dossiers_GroupFragment>>
  | Subject<Flat<Application_Actions_HistoryFragment>>
  | Subject<Flat<FileFragment>>
  | Subject<Flat<LiquidationFragment>>
  | Subject<Flat<Subvention_ProjectFragment>>
  | Subject<Flat<Pdf_TemplateFragment>>
  | Subject<Flat<Pdf_ConfigFragment>>
  | Subject<Flat<Pdf_DocumentFragment>>
  | Subject<Flat<Contact_InfoFragment>>
  | Subject<Flat<Values_ListFragment>>
  | Subject<Flat<Sub_DepartmentFragment>>
  | Subject<Flat<DepartmentFragment>>
  | Subject<Flat<OrganisationFragment>>
  | Subject<Flat<ValidationFragment>>
  | Subject<Flat<MembershipFragment>>
  | "User"
  | "Experiment"
  | "Faq"
  | "Repository"
  | "keycloak-users"
  | "all";

export type FlatAppAbility = Ability<[Actions, FlatSubjects]>;

// FIXME: let's try to make this type work
// export type CanProps = Omit<React.ComponentPropsWithoutRef<typeof Can>, "children">;
export type CanProps = {
  I: Actions;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  a: any;
  field?: string;
  not?: boolean;
};

export type Permission = {
  action: Actions;
  subject: Subjects;
  fields?: string;
};

export type Permissions = Permission[];
// TODO: this should be replaced by the CanProps type
export type CanPropsBis = {
  I: Actions;
  this:
    | MemoFragment
    | DossierFragment
    | ServiceFragment
    | DispositifFragment
    | UserFragment
    | Dossiers_GroupFragment
    | Application_Actions_HistoryFragment
    | FileFragment
    | LiquidationFragment
    | Subvention_ProjectFragment
    | Pdf_TemplateFragment
    | Pdf_ConfigFragment
    | Pdf_DocumentFragment
    | Values_ListFragment
    | Sub_DepartmentFragment
    | DepartmentFragment
    | OrganisationFragment
    | ValidationFragment
    | MembershipFragment
    | Contact_InfoFragment
    | Reference_DispositifFragment;
  // this: Extract<Generics<AppAbility>["abilities"][1], SubjectType>;
  field?: string;
};
