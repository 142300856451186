import type { AbilityBuilder } from "@casl/ability";
import { APPLICATION_STEP, SUBVENTION_PROJECT_STEP } from "~/constants/steps/steps";
import { ROLES } from "~/constants/users";
import type { User } from "~/types/database";
import type { FlatAppAbility } from "~/types/permissions";
import { getSubDepartmentIdsAsRoleType } from "~/utils/user-role";
export const getSubDepartmentHeadPermissions = ({
  can,
  subDepartmentHead
}: {
  can: AbilityBuilder<FlatAppAbility>["_addRule"];
  subDepartmentHead: User;
}) => {
  const userSubdepartmentIds = getSubDepartmentIdsAsRoleType(subDepartmentHead, ROLES.SUB_DEPARTMENT_HEAD);
  can("seeBo", "dossiers");
  can("read", "dossiers", "applicant.isActive", {
    "applicant.is_active": {
      $eq: false
    }
  });
  can("read", "dossiers", "organisation.isActive");
  can("read", "dossiers", ["id", "application_form_answers"], {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    "service.sub_department_id": {
      $in: userSubdepartmentIds
    }
  });
  can("read", "dossiers", "validationsData.decision", {
    step: {
      $nin: [APPLICATION_STEP.SUBMISSION, APPLICATION_STEP.ASSIGNMENT, APPLICATION_STEP.VERIFICATION, APPLICATION_STEP.MODIFICATION, APPLICATION_STEP.DECISION]
    },
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    "service.sub_department_id": {
      $in: userSubdepartmentIds
    }
  });
  can("update", "dossiers", "handler_id", {
    step: {
      $ne: APPLICATION_STEP.ARCHIVED
    },
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    "service.sub_department_id": {
      $in: userSubdepartmentIds
    }
  });
  can("update", "dossiers", "service_id", {
    step: {
      $nin: [APPLICATION_STEP.SUBMISSION, APPLICATION_STEP.ARCHIVED]
    },
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    "service.sub_department_id": {
      $in: userSubdepartmentIds
    }
  });
  can("read", "dossiers", "subvention_projects", {
    step: {
      $in: [APPLICATION_STEP.REDACTION, APPLICATION_STEP.ACTIVE, APPLICATION_STEP.CLOSURE, APPLICATION_STEP.CONTROL, APPLICATION_STEP.CONTROL_MODIFICATION, APPLICATION_STEP.CONTROL_OVERPAYMENT, APPLICATION_STEP.CONTROL_VERIFICATION, APPLICATION_STEP.ARCHIVED]
    },
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    "service.sub_department_id": {
      $in: userSubdepartmentIds
    }
  });
  can("update", "dossiers", "subvention_projects", {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    "service.sub_department_id": {
      $in: userSubdepartmentIds
    }
  });
  can("read", "dossiers", "liquidation", {
    step: {
      $in: [APPLICATION_STEP.ACTIVE, APPLICATION_STEP.CLOSURE, APPLICATION_STEP.CONTROL, APPLICATION_STEP.CONTROL_MODIFICATION, APPLICATION_STEP.CONTROL_OVERPAYMENT, APPLICATION_STEP.CONTROL_VERIFICATION, APPLICATION_STEP.ARCHIVED]
    },
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    "service.sub_department_id": {
      $in: userSubdepartmentIds
    }
  });
  can("read", "dossiers", "control_requested", {
    control_requested: {
      $eq: true
    },
    step: {
      $in: [APPLICATION_STEP.CONTROL, APPLICATION_STEP.CONTROL_VERIFICATION, APPLICATION_STEP.CONTROL_MODIFICATION, APPLICATION_STEP.CONTROL_OVERPAYMENT]
    },
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    "service.sub_department_id": {
      $in: userSubdepartmentIds
    }
  });
  can("read", "dossiers", "control_requested", {
    controlled: true,
    step: APPLICATION_STEP.ARCHIVED,
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    "service.sub_department_id": {
      $in: userSubdepartmentIds
    }
  });
  can("read", "dossiers", "control_requested", {
    closed_early: true,
    has_overpayment: true,
    step: {
      $in: [APPLICATION_STEP.ARCHIVED, APPLICATION_STEP.CONTROL_OVERPAYMENT]
    },
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    "service.sub_department_id": {
      $in: userSubdepartmentIds
    }
  });

  // SUBVENTION PROJECTS

  can("update", "subvention_projects", "all", {
    "dossier.service.sub_department_id": {
      $in: userSubdepartmentIds
    },
    step: SUBVENTION_PROJECT_STEP.SUB_DEPARTMENT_HEAD_VALIDATION
  });
  can(["read", "update"], "subvention_projects", "step", {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    "dossier.service.sub_department_id": {
      $in: userSubdepartmentIds
    },
    step: SUBVENTION_PROJECT_STEP.SUB_DEPARTMENT_HEAD_VALIDATION
  });
  can("create", "subvention_projects", "validations", {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    "dossier.service.sub_department_id": {
      $in: userSubdepartmentIds
    },
    step: SUBVENTION_PROJECT_STEP.SUB_DEPARTMENT_HEAD_VALIDATION
  });

  // PIA database
  can("read", "PIA-database");

  // MEMOS
  can(["create", "read"], "memos");

  // USERS
  can("read", "users", "id");

  // PDF
  can("read", "pdf_documents");
};