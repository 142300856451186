import { i18n } from "~/i18nSetup";
import type { Gender, Role } from "~/types/database";
import type { UsersTab } from "~/types/users";

export const USERS_TABS = {
  INTERNAL_USERS: "internal-users",
  EXTERNAL_USERS: "external-users",
  ORGANISATIONS: "organismes",
} as const;

export const USERS_TAB_LABELS: Record<UsersTab, string> = {
  [USERS_TABS.INTERNAL_USERS]: i18n._({ id: "users.tabs.internal_users", message: "Utilisateurs internes" }),
  [USERS_TABS.EXTERNAL_USERS]: i18n._({ id: "users.tabs.external_users", message: "Utilisateurs externes" }),
  [USERS_TABS.ORGANISATIONS]: i18n._({ id: "users.tabs.organisations", message: "Organismes" }),
};

export const APPLICANT_ROLE = {
  APPLICANT: "applicant",
} as const;

export const WBI_HIERARCHY_INDEPENDENT_ROLES = {
  ACCOUNTANT: "accountant",
  TREASURER: "treasurer",
  ADMIN: "admin",
  AUTHORIZING_OFFICER: "authorizing_officer",
  COMMITMENT_CONTROLLER: "commitment_controller",
  FINANCIAL_INSPECTOR: "financial_inspector",
  MINISTER: "minister",
} as const;

export const WBI_SERVICE_DEPENDENT_ROLES = {
  HANDLER: "handler",
  HANDLER_MANAGER: "handler_manager",
  SERVICE_HEAD: "service_head",
} as const;

export const WBI_SUB_DEPARTMENT_DEPENDENT_ROLES = {
  SUB_DEPARTMENT_HEAD: "sub_department_head",
} as const;

export const WBI_DEPARTMENT_DEPENDENT_ROLES = {
  DEPARTMENT_HEAD: "department_head",
} as const;

const ROLES = {
  ...APPLICANT_ROLE,
  ...WBI_HIERARCHY_INDEPENDENT_ROLES,
  ...WBI_SERVICE_DEPENDENT_ROLES,
  ...WBI_SUB_DEPARTMENT_DEPENDENT_ROLES,
  ...WBI_DEPARTMENT_DEPENDENT_ROLES,
};

const STATIC_ROLES: Record<Role["type"], string> = {
  [ROLES.ACCOUNTANT]: i18n._({ id: "roles.accountant", message: "Agent comptable" }),
  [ROLES.ADMIN]: i18n._({ id: "roles.admin", message: "Admin" }),
  [ROLES.APPLICANT]: i18n._({ id: "roles.applicant", message: "Demandeur" }),
  [ROLES.AUTHORIZING_OFFICER]: i18n._({ id: "roles.authorizing_officer", message: "Ordonnateur" }),
  [ROLES.COMMITMENT_CONTROLLER]: i18n._({ id: "roles.commitment_controller", message: "Contrôleur des engagements" }),
  [ROLES.FINANCIAL_INSPECTOR]: i18n._({ id: "roles.financial_inspector", message: "Inspecteur des finances" }),
  [ROLES.HANDLER]: i18n._({ id: "roles.handler", message: "Agent traitant" }),
  [ROLES.HANDLER_MANAGER]: i18n._({ id: "roles.handler_manager", message: "Agent traitant étendu" }),
  [ROLES.MINISTER]: i18n._({ id: "roles.minister", message: "Ministre" }),
  [ROLES.SERVICE_HEAD]: i18n._({ id: "roles.service_head", message: "Chef de service" }),
  [ROLES.SUB_DEPARTMENT_HEAD]: i18n._({ id: "roles.sub_department_head", message: "Chef de sous-département" }),
  [ROLES.DEPARTMENT_HEAD]: i18n._({ id: "roles.department_head", message: "Chef de département" }),
  [ROLES.TREASURER]: i18n._({ id: "roles.treasurer", message: "Trésorier" }),
};

export const WBI_INTERNAL_ASSIGNABLE_ROLES = {
  [ROLES.HANDLER]: i18n._({ id: "roles.handler", message: "Agent traitant" }),
  [ROLES.HANDLER_MANAGER]: i18n._({ id: "roles.handler_manager", message: "Agent traitant étendu" }),
  [ROLES.SERVICE_HEAD]: i18n._({ id: "roles.service_head", message: "Chef de service" }),
  [ROLES.SUB_DEPARTMENT_HEAD]: i18n._({ id: "roles.sub_department_head", message: "Chef de sous-département" }),
  [ROLES.DEPARTMENT_HEAD]: i18n._({ id: "roles.department_head", message: "Chef de département" }),
  [ROLES.COMMITMENT_CONTROLLER]: i18n._({ id: "roles.commitment_controller", message: "Contrôleur des engagements" }),
  [ROLES.FINANCIAL_INSPECTOR]: i18n._({ id: "roles.financial_inspector", message: "Inspecteur des finances" }),
  [ROLES.AUTHORIZING_OFFICER]: i18n._({ id: "roles.authorizing_officer", message: "Ordonnateur" }),
  [ROLES.MINISTER]: i18n._({ id: "roles.minister", message: "Ministre" }),
  [ROLES.ADMIN]: i18n._({ id: "roles.admin", message: "Admin" }),
  [ROLES.ACCOUNTANT]: i18n._({ id: "roles.accountant", message: "Agent comptable" }),
  [ROLES.TREASURER]: i18n._({ id: "roles.treasurer", message: "Trésorier" }),
};

export const WBI_INTERNAL_ASSIGNABLE_ROLE_OPTIONS = Object.entries(WBI_INTERNAL_ASSIGNABLE_ROLES).map(([value]) => ({
  id: value,
  label: STATIC_ROLES[value as Role["type"]],
}));

const USER_BADGE_STYLES = [
  { backgroundColor: "bg-royal-blue" },
  { backgroundColor: "bg-sky-blue" },
  { backgroundColor: "bg-blue-grey" },
  { backgroundColor: "bg-aubergine" },
  { backgroundColor: "bg-purple" },
  { backgroundColor: "bg-sparkling-grape" },
  { backgroundColor: "bg-brown" },
  { backgroundColor: "bg-bordeau" },
  { backgroundColor: "bg-cherry-red" },
] as const;

const USER_GENDER = {
  MEN: "M",
  WOMEN: "F",
} as const;

const STATIC_USER_GENDER: Record<Gender, string> = {
  [USER_GENDER.MEN]: i18n._({ id: "gender.male", message: "Homme" }),
  [USER_GENDER.WOMEN]: i18n._({ id: "gender.female", message: "Femme" }),
};

const USER_GENDER_OPTIONS: { id: Gender; label: string }[] = Object.entries(STATIC_USER_GENDER).map((entry) => ({ id: entry[0] as Gender, label: entry[1] }));

export const STATUS = {
  TRUE: "true",
  FALSE: "false",
} as const;

const STATIC_STATUS: Record<"true" | "false", string> = {
  true: i18n._({ id: "status.active", message: "Actif" }),
  false: i18n._({ id: "status.inactive", message: "Inactif" }),
};

export { ROLES, STATIC_ROLES, USER_BADGE_STYLES, USER_GENDER, STATIC_USER_GENDER, USER_GENDER_OPTIONS, STATIC_STATUS };
