export const ORGANISATIONS_USERS_ROLES = {
  ADMIN: "admin",
  COLLABORATOR: "collaborator",
} as const;

export const ORGANISATION_USERS_STATIC_ROLES: Record<string, string> = {
  [ORGANISATIONS_USERS_ROLES.ADMIN]: "Administrateur",
  [ORGANISATIONS_USERS_ROLES.COLLABORATOR]: "Collaborateur",
};

export const MISSING_BENEFICIARY: string = "Ce bénéficiaire n’existe plus. Changez le.";
