import { trpc } from "~/utils/trpc";

// I explicitly suffixed this hook name with *Data so that there is no confusion and we know that we don't get an iron-session session
// with save and destroy methods (they are only available on the server side) and only the session data
export const useSessionData = () => {
  const { isLoading, data } = trpc.auth.getSessionData.useQuery(undefined, { retry: false });

  return {
    status: isLoading ? "loading" : "loaded",
    data,
  };
};
