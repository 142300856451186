import { keys } from "lodash";
import { z } from "zod";

import { i18n } from "~/i18nSetup";
import type { OrganisationStep } from "~/types/organisations";

export const ORGANISATION_STEP = {
  VALIDATION: "validation",
  MODIFICATION: "modification",
  APPROVED: "approved",
} as const;

export const ORGANISATION_STATIC_STEP = {
  [ORGANISATION_STEP.VALIDATION]: i18n._({ id: "organisation.steps.validation", message: "Validation" }),
  [ORGANISATION_STEP.MODIFICATION]: i18n._({ id: "organisation.steps.modification", message: "Modification" }),
  [ORGANISATION_STEP.APPROVED]: i18n._({ id: "organisation.steps.approved", message: "Actif" }),
} satisfies Record<OrganisationStep, string>;

export const APPLICATION_STEP = {
  SUBMISSION: "submission",
  ASSIGNMENT: "assignment",
  VERIFICATION: "verification",
  MODIFICATION: "modification",
  DECISION: "decision",
  REFUSAL_BAD_NEWS_LETTER_WRITING: "refusal_bad_news_letter_writing",
  REFUSAL_SERVICE_HEAD_VALIDATION: "refusal_service_head_validation",
  REFUSAL_AUTHORIZING_OFFICER_VALIDATION: "refusal_authorizing_officer_validation",
  REFUSAL_AUTHORIZING_OFFICER_SIGNATURE: "refusal_authorizing_officer_signature",
  REFUSAL_MODIFICATION: "refusal_modification",
  REDACTION: "redaction",
  ACTIVE: "active",
  CLOSURE: "closure",
  CONTROL: "control",
  CONTROL_VERIFICATION: "control_verification",
  CONTROL_MODIFICATION: "control_modification",
  CONTROL_OVERPAYMENT: "control_overpayment",
  ARCHIVED: "archived",
} as const;

export type ApplicationStep = (typeof APPLICATION_STEP)[keyof typeof APPLICATION_STEP];

export type ApplicationStaticStepValue = {
  mandatory: boolean;
  order: number;
  extra?: ({
    foStep,
    currentBoStep,
    currentFoStepDeducedFromCurrentBoStep,
    dueDate,
  }: {
    foStep: string;
    currentBoStep: string;
    currentFoStepDeducedFromCurrentBoStep: string;
    dueDate?: {
      start: string;
      end: string;
    };
  }) => string | null;
};

// The order of the steps should be in the usual step order because the order is used in the STEP_ORDER constant
export const APPLICATION_STATIC_STEPS: Record<string, { text: string; color: string }> = {
  [APPLICATION_STEP.SUBMISSION]: {
    text: i18n._({ id: "application.steps.submission", message: "Soumission" }),
    color: "#79CFF3",
  },
  [APPLICATION_STEP.ASSIGNMENT]: {
    text: i18n._({ id: "application.steps.assignment", message: "Attribution" }),
    color: "#4E6BA9",
  },
  [APPLICATION_STEP.VERIFICATION]: {
    text: i18n._({ id: "application.steps.verification", message: "Vérification" }),
    color: "#8FA2CC",
  },
  [APPLICATION_STEP.MODIFICATION]: {
    text: i18n._({ id: "application.steps.modification", message: "Modification" }),
    color: "#7D9AC4",
  },
  [APPLICATION_STEP.DECISION]: {
    text: i18n._({ id: "application.steps.decision", message: "Décision" }),
    color: "#3F1950",
  },
  [APPLICATION_STEP.REFUSAL_BAD_NEWS_LETTER_WRITING]: {
    text: i18n._({ id: "application.steps.refusal_bad_news_letter_writing", message: "Lettre de mauvaise nouvelle" }),
    color: "#654087",
  },
  [APPLICATION_STEP.REFUSAL_SERVICE_HEAD_VALIDATION]: {
    text: i18n._({ id: "application.steps.refusal_service_head_validation", message: "Validation chef de service" }),
    color: "#9D5AA4",
  },
  [APPLICATION_STEP.REFUSAL_AUTHORIZING_OFFICER_VALIDATION]: {
    text: i18n._({ id: "application.steps.refusal_authorizing_officer_validation", message: "Validation ordonnateur" }),
    color: "#560017",
  },
  [APPLICATION_STEP.REFUSAL_AUTHORIZING_OFFICER_SIGNATURE]: {
    text: i18n._({ id: "application.steps.refusal_authorizing_officer_signature", message: "Signature lettre de mauvaise nouvelle" }),
    color: "#881539",
  },
  [APPLICATION_STEP.REFUSAL_MODIFICATION]: {
    text: i18n._({ id: "application.steps.refusal_modification", message: "Modification" }),
    color: "#676767",
  },
  [APPLICATION_STEP.REDACTION]: {
    text: i18n._({ id: "application.steps.redaction", message: "Rédaction" }),
    color: "#654087",
  },
  [APPLICATION_STEP.ACTIVE]: {
    text: i18n._({ id: "application.steps.active", message: "Actif" }),
    color: "#9D5AA4",
  },
  [APPLICATION_STEP.CLOSURE]: {
    text: i18n._({ id: "application.steps.closure", message: "Clôture" }),
    color: "#4E6BA9",
  },
  [APPLICATION_STEP.CONTROL]: {
    text: i18n._({ id: "application.steps.control", message: "Contrôle" }),
    color: "#560017",
  },
  [APPLICATION_STEP.CONTROL_VERIFICATION]: {
    text: i18n._({ id: "application.steps.control_verification", message: "Validation contrôle" }),
    color: "#4E6BA9",
  },
  [APPLICATION_STEP.CONTROL_MODIFICATION]: {
    text: i18n._({ id: "application.steps.control_modification", message: "Modification" }),
    color: "#4E6BA9",
  },
  [APPLICATION_STEP.CONTROL_OVERPAYMENT]: {
    text: i18n._({ id: "application.steps.control_overpayment", message: "Trop perçu" }),
    color: "#4E6BA9",
  },
  [APPLICATION_STEP.ARCHIVED]: {
    text: i18n._({ id: "application.steps.archived", message: "Archivé" }),
    color: "#8792A1",
  },
} satisfies Record<ApplicationStep, { text: string; color: string }>;

export type LiquidationStep = (typeof LIQUIDATION_STEP)[keyof typeof LIQUIDATION_STEP];
export type AutomaticLiquidationStep = (typeof AUTOMATIC_LIQUIDATION_STEP)[keyof typeof AUTOMATIC_LIQUIDATION_STEP];

export const LIQUIDATION_STEP = {
  SUBMISSION: "submission",
  VERIFICATION: "verification",
  MODIFICATION: "modification",
  SERVICE_HEAD_VALIDATION: "service_head_validation",
  ACCOUNTANT_VALIDATION: "accountant_validation",
  PAYMENT: "payment",
  COMPLETED: "completed",
} as const;

export const AUTOMATIC_LIQUIDATION_STEP = {
  PAYMENT: "payment",
  COMPLETED: "completed",
} as const;

export const boStepLiquidationValidator = z.nativeEnum(LIQUIDATION_STEP);
export const boStepAutomaticLiquidationValidator = z.nativeEnum(AUTOMATIC_LIQUIDATION_STEP);

export type SubventionProjectStep = (typeof SUBVENTION_PROJECT_STEP)[keyof typeof SUBVENTION_PROJECT_STEP];

export const SUBVENTION_PROJECT_STEP = {
  CREATION: "creation",
  MODIFICATION: "modification",
  SERVICE_HEAD_VALIDATION: "service_head_validation",
  SUB_DEPARTMENT_HEAD_VALIDATION: "sub_department_head_validation",
  DEPARTMENT_HEAD_VALIDATION: "department_head_validation",
  COMMITMENT_CONTROLLER_VALIDATION: "commitment_controller_validation",
  AUTHORIZING_OFFICER_VALIDATION: "authorizing_officer_validation",
  FINANCIAL_INSPECTOR_OPINION: "financial_inspector_opinion",
  MINISTER_VALIDATION: "minister_validation",
  AUTHORIZING_OFFICER_SECOND_VALIDATION: "authorizing_officer_second_validation",
  AUTHORIZING_OFFICER_GRANT_DECREE_SIGNATURE: "authorizing_officer_grant_decree_signature",
  AUTHORIZING_OFFICER_AMENDMENT_DECREE_SIGNATURE: "authorizing_officer_amendment_decree_signature",
  AUTHORIZING_OFFICER_GOOD_NEWS_LETTER_SIGNATURE: "authorizing_officer_good_news_letter_signature",
  MINISTER_GRANT_DECREE_SIGNATURE: "minister_grant_decree_signature",
  MINISTER_AMENDMENT_DECREE_SIGNATURE: "minister_amendment_decree_signature",
  COMPLETED: "completed",
} as const;

export const LIQUIDATION_STATIC_STEPS: Record<string, { text: string; color: string }> = {
  [LIQUIDATION_STEP.SUBMISSION]: {
    text: i18n._({ id: "liquidation.steps.submission", message: "Soumission" }),
    color: "#79CFF3",
  },
  [LIQUIDATION_STEP.VERIFICATION]: {
    text: i18n._({ id: "liquidation.steps.verification", message: "Vérification" }),
    color: "#4E6BA9",
  },
  [LIQUIDATION_STEP.MODIFICATION]: {
    text: i18n._({ id: "liquidation.steps.modification", message: "Modification" }),
    color: "#4E6BA9",
  },
  [LIQUIDATION_STEP.SERVICE_HEAD_VALIDATION]: {
    text: i18n._({ id: "liquidation.steps.service_head_validation", message: "Validation chef de service" }),
    color: "#8FA2CC",
  },
  [LIQUIDATION_STEP.ACCOUNTANT_VALIDATION]: {
    text: i18n._({ id: "liquidation.steps.accountant_validation", message: "Validation agent comptable" }),
    color: "#3F1950",
  },
  [LIQUIDATION_STEP.PAYMENT]: {
    text: i18n._({ id: "liquidation.steps.payment", message: "Paiement" }),
    color: "#654087",
  },
  [LIQUIDATION_STEP.COMPLETED]: {
    text: i18n._({ id: "liquidation.steps.completed", message: "Terminée" }),
    color: "#8FA2CC",
  },
} satisfies Record<LiquidationStep, { text: string; color: string }>;

export const SUBVENTION_PROJECT_STATIC_STEPS: Record<string, { text: string; color: string }> = {
  [SUBVENTION_PROJECT_STEP.CREATION]: {
    text: i18n._({ id: "subvention_project.steps.creation", message: "Soumission" }),
    color: "#79CFF3",
  },
  [SUBVENTION_PROJECT_STEP.MODIFICATION]: {
    text: i18n._({ id: "subvention_project.steps.modification", message: "Modification" }),
    color: "#79CFF3",
  },
  [SUBVENTION_PROJECT_STEP.SERVICE_HEAD_VALIDATION]: {
    text: i18n._({ id: "subvention_project.steps.service_head_validation", message: "Validation chef de service" }),
    color: "#4E6BA9",
  },
  [SUBVENTION_PROJECT_STEP.SUB_DEPARTMENT_HEAD_VALIDATION]: {
    text: i18n._({ id: "subvention_project.steps.sub_department_head_validation", message: "Validation chef de sous-département" }),
    color: "#8FA2CC",
  },
  [SUBVENTION_PROJECT_STEP.DEPARTMENT_HEAD_VALIDATION]: {
    text: i18n._({ id: "subvention_project.steps.department_head_validation", message: "Validation chef de département" }),
    color: "#3F1950",
  },
  [SUBVENTION_PROJECT_STEP.COMMITMENT_CONTROLLER_VALIDATION]: {
    text: i18n._({ id: "subvention_project.steps.commitment_controller_validation", message: "Validation contrôleur des engagements" }),
    color: "#654087",
  },
  [SUBVENTION_PROJECT_STEP.AUTHORIZING_OFFICER_VALIDATION]: {
    text: i18n._({ id: "subvention_project.steps.authorizing_officer_validation", message: "Validation ordonnateur" }),
    color: "#79CFF3",
  },
  [SUBVENTION_PROJECT_STEP.FINANCIAL_INSPECTOR_OPINION]: {
    text: i18n._({ id: "subvention_project.steps.financial_inspector_opinion", message: "Avis inspecteur des finances" }),
    color: "#560017",
  },
  [SUBVENTION_PROJECT_STEP.AUTHORIZING_OFFICER_SECOND_VALIDATION]: {
    text: i18n._({ id: "subvention_project.steps.authorizing_officer_second_validation", message: "Validation ordonnateur" }),
    color: "#9D5AA4",
  },
  [SUBVENTION_PROJECT_STEP.AUTHORIZING_OFFICER_GRANT_DECREE_SIGNATURE]: {
    text: i18n._({ id: "subvention_project.steps.authorizing_officer_grant_decree_signature", message: "Signature arrêté ordonnateur" }),
    color: "#560017",
  },
  [SUBVENTION_PROJECT_STEP.AUTHORIZING_OFFICER_AMENDMENT_DECREE_SIGNATURE]: {
    text: i18n._({ id: "subvention_project.steps.authorizing_officer_amendment_decree_signature", message: "Signature arrêté ordonnateur" }),
    color: "#79CFF3",
  },
  [SUBVENTION_PROJECT_STEP.AUTHORIZING_OFFICER_GOOD_NEWS_LETTER_SIGNATURE]: {
    text: i18n._({ id: "subvention_project.steps.authorizing_officer_good_news_letter_signature", message: "Signature lettre de bonne nouvelle" }),
    color: "#4E6BA9",
  },
  [SUBVENTION_PROJECT_STEP.MINISTER_VALIDATION]: {
    text: i18n._({ id: "subvention_project.steps.minister_validation", message: "Validation ministre" }),
    color: "#881539",
  },
  [SUBVENTION_PROJECT_STEP.MINISTER_GRANT_DECREE_SIGNATURE]: {
    text: i18n._({ id: "subvention_project.steps.minister_grant_decree_signature", message: "Signature arrêté ministre" }),
    color: "#79CFF3",
  },
  [SUBVENTION_PROJECT_STEP.MINISTER_AMENDMENT_DECREE_SIGNATURE]: {
    text: i18n._({ id: "subvention_project.steps.minister_amendment_decree_signature", message: "Signature arrêté ministre" }),
    color: "#79CFF3",
  },
  [SUBVENTION_PROJECT_STEP.COMPLETED]: {
    text: i18n._({ id: "subvention_project.steps.completed", message: "Terminé" }),
    color: "#8FA2CC",
  },
} satisfies Record<SubventionProjectStep, { text: string; color: string }>;

export const APPLICATION_STEP_ORDER = keys(APPLICATION_STATIC_STEPS);
export const STEP_LIQUIDATION_ORDER = keys(LIQUIDATION_STATIC_STEPS);
export const STEP_SUBVENTION_PROJECT_ORDER = keys(SUBVENTION_PROJECT_STATIC_STEPS);
