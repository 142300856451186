import type { AbilityBuilder } from "@casl/ability";
import { SUBVENTION_PROJECT_STEP } from "~/constants/steps/steps";
import type { FlatAppAbility } from "~/types/permissions";
export const getFinancialInspectorPermissions = ({
  can
}: {
  can: AbilityBuilder<FlatAppAbility>["_addRule"];
}) => {
  can("read", "dossiers", ["id", "application_form_answers"], {
    subvention_projects: {
      $elemMatch: {
        step: {
          $eq: SUBVENTION_PROJECT_STEP.FINANCIAL_INSPECTOR_OPINION
        }
      }
    }
  });
  can("seeBo", "dossiers");
  can(["create", "read"], "memos");

  // Subvention

  can(["read", "update"], "dossiers", "subvention_projects", {
    subvention_projects: {
      $elemMatch: {
        step: {
          $eq: SUBVENTION_PROJECT_STEP.FINANCIAL_INSPECTOR_OPINION
        }
      }
    }
  });
  can("read", "subvention_projects", "validations.opinion", {
    step: {
      $eq: SUBVENTION_PROJECT_STEP.FINANCIAL_INSPECTOR_OPINION
    }
  });
  can("create", "subvention_projects", "validations", {
    step: {
      $eq: SUBVENTION_PROJECT_STEP.FINANCIAL_INSPECTOR_OPINION
    }
  });

  // PIA database
  //can("read", "PIA-database");

  // PDF DOCUMENTS
  can("read", "pdf_documents");
};