import type { AbilityBuilder } from "@casl/ability";
import { APPLICATION_STEP, SUBVENTION_PROJECT_STEP } from "~/constants/steps/steps";
import type { FlatAppAbility } from "~/types/permissions";
export const getMinisterPermissions = ({
  can
}: {
  can: AbilityBuilder<FlatAppAbility>["_addRule"];
}) => {
  can("read", "dossiers", ["id", "application_form_answers"], {
    step: {
      $in: [APPLICATION_STEP.REDACTION, APPLICATION_STEP.ACTIVE]
    }
  });
  can("seeBo", "dossiers");
  can(["create", "read"], "memos");

  // Subvention

  can(["read", "update"], "dossiers", "subvention_projects", {
    subvention_projects: {
      $elemMatch: {
        step: {
          $in: [SUBVENTION_PROJECT_STEP.MINISTER_VALIDATION, SUBVENTION_PROJECT_STEP.MINISTER_AMENDMENT_DECREE_SIGNATURE, SUBVENTION_PROJECT_STEP.MINISTER_GRANT_DECREE_SIGNATURE]
        }
      }
    }
  });
  can("read", "subvention_projects", "step", {
    step: {
      $in: [SUBVENTION_PROJECT_STEP.MINISTER_VALIDATION]
    }
  });
  can("create", "subvention_projects", "validations", {
    step: {
      $eq: SUBVENTION_PROJECT_STEP.MINISTER_VALIDATION
    }
  });
  can("create", "subvention_projects", "files", {
    step: {
      $in: [SUBVENTION_PROJECT_STEP.MINISTER_GRANT_DECREE_SIGNATURE, SUBVENTION_PROJECT_STEP.MINISTER_AMENDMENT_DECREE_SIGNATURE]
    }
  });
  can("create", "subvention_projects", "grant_decree_file", {
    step: {
      $in: [SUBVENTION_PROJECT_STEP.MINISTER_GRANT_DECREE_SIGNATURE]
    }
  });
  can("create", "subvention_projects", "amendment_decree_file", {
    step: {
      $in: [SUBVENTION_PROJECT_STEP.MINISTER_AMENDMENT_DECREE_SIGNATURE]
    }
  });

  // PIA database
  //can("read", "PIA-database");

  // PDF DOCUMENTS
  can("read", "pdf_documents");
};