import type { AbilityBuilder } from "@casl/ability";
import { APPLICATION_STEP, LIQUIDATION_STEP, SUBVENTION_PROJECT_STEP } from "~/constants/steps/steps";
import { ROLES } from "~/constants/users";
import type { User } from "~/types/database";
import type { FlatAppAbility } from "~/types/permissions";
import { getServiceIdsAsRoleType } from "~/utils/user-role";
export const getServiceHeadPermissions = ({
  can,
  serviceHead
}: {
  can: AbilityBuilder<FlatAppAbility>["_addRule"];
  serviceHead: User;
}) => {
  const serviceIdsAsServiceHead = getServiceIdsAsRoleType(serviceHead, ROLES.SERVICE_HEAD);

  //DOSSIER
  can("seeBo", "dossiers");
  can("read", "dossiers", "applicant.isActive", {
    "applicant.is_active": {
      $eq: false
    }
  });
  can("read", "dossiers", "organisation.isActive");
  can("read", "dossiers", "validationsData.decision", {
    service_id: {
      $in: serviceIdsAsServiceHead
    },
    step: {
      $nin: [APPLICATION_STEP.SUBMISSION, APPLICATION_STEP.ASSIGNMENT, APPLICATION_STEP.VERIFICATION, APPLICATION_STEP.MODIFICATION, APPLICATION_STEP.DECISION]
    }
  });
  can("read", "dossiers", ["id", "application_form_answers"], {
    service_id: {
      $in: serviceIdsAsServiceHead
    }
  });
  can("read", "dossiers", "application_form_answers", {
    service_id: {
      $in: serviceIdsAsServiceHead
    },
    step: {
      $nin: [APPLICATION_STEP.SUBMISSION]
    }
  });
  can("update", "dossiers", "handler_id", {
    service_id: {
      $in: serviceIdsAsServiceHead
    },
    step: {
      $nin: [APPLICATION_STEP.ARCHIVED, APPLICATION_STEP.SUBMISSION]
    }
  });
  can("read", "dossiers", "handlerMatch", {
    service_id: {
      $in: serviceIdsAsServiceHead
    }
  });
  can("update", "dossiers", "service_id", {
    service_id: {
      $in: serviceIdsAsServiceHead
    },
    step: {
      $nin: [APPLICATION_STEP.SUBMISSION, APPLICATION_STEP.ARCHIVED]
    }
  });
  can("read", "dossiers", "bad_news_letter_file", {
    service_id: {
      $in: serviceIdsAsServiceHead
    },
    step: {
      $eq: APPLICATION_STEP.REFUSAL_SERVICE_HEAD_VALIDATION
    }
  });
  can("update", "dossiers", "bad_news_letter", {
    service_id: {
      $in: serviceIdsAsServiceHead
    },
    step: {
      $eq: APPLICATION_STEP.REFUSAL_SERVICE_HEAD_VALIDATION
    }
  });
  can("create", "dossiers", "validations.bad_news_letter", {
    service_id: {
      $in: serviceIdsAsServiceHead
    },
    step: {
      $eq: APPLICATION_STEP.REFUSAL_SERVICE_HEAD_VALIDATION
    }
  });
  can(["create", "read"], "memos");
  can("update", "dossiers", "applicant_id", {
    service_id: {
      $in: serviceIdsAsServiceHead
    },
    organisation: {
      $exists: true
    },
    step: {
      $nin: [APPLICATION_STEP.SUBMISSION, APPLICATION_STEP.ARCHIVED]
    }
  });
  can(["read", "update"], "dossiers", "subvention_projects", {
    service_id: {
      $in: serviceIdsAsServiceHead
    },
    step: {
      $in: [APPLICATION_STEP.REDACTION, APPLICATION_STEP.ACTIVE, APPLICATION_STEP.CLOSURE, APPLICATION_STEP.CONTROL, APPLICATION_STEP.CONTROL_MODIFICATION, APPLICATION_STEP.CONTROL_OVERPAYMENT, APPLICATION_STEP.ARCHIVED]
    }
  });
  can("read", "dossiers", "liquidation", {
    service_id: {
      $in: serviceIdsAsServiceHead
    },
    step: {
      $in: [APPLICATION_STEP.ACTIVE, APPLICATION_STEP.CLOSURE, APPLICATION_STEP.CONTROL, APPLICATION_STEP.CONTROL_MODIFICATION, APPLICATION_STEP.CONTROL_OVERPAYMENT, APPLICATION_STEP.CONTROL_VERIFICATION]
    }
  });
  can("read", "dossiers", "control_requested", {
    service_id: {
      $in: serviceIdsAsServiceHead
    },
    control_requested: {
      $eq: true
    },
    step: {
      $in: [APPLICATION_STEP.CONTROL, APPLICATION_STEP.CONTROL_VERIFICATION, APPLICATION_STEP.CONTROL_MODIFICATION, APPLICATION_STEP.CONTROL_OVERPAYMENT]
    }
  });
  can("read", "dossiers", "control_requested", {
    service_id: {
      $in: serviceIdsAsServiceHead
    },
    controlled: true,
    step: APPLICATION_STEP.ARCHIVED
  });
  can("read", "dossiers", "control_requested", {
    service_id: {
      $in: serviceIdsAsServiceHead
    },
    closed_early: true,
    has_overpayment: true,
    step: {
      $in: [APPLICATION_STEP.ARCHIVED, APPLICATION_STEP.CONTROL_OVERPAYMENT]
    }
  });

  // DISPOSITIFS

  can(["create", "read"], "dispositifs", {
    service_id: {
      $in: serviceIdsAsServiceHead
    }
  });
  can("update", "dispositifs", "id", {
    status: "draft",
    service_id: {
      $in: serviceIdsAsServiceHead
    }
  });
  can("update", "dispositifs", ["label", "status"], {
    status: "published",
    service_id: {
      $in: serviceIdsAsServiceHead
    }
  });
  can("delete", "dispositifs", {
    status: "draft",
    service_id: {
      $in: serviceIdsAsServiceHead
    }
  });
  can("read", "services", {
    users_roles: {
      $elemMatch: {
        service_id: {
          $in: serviceIdsAsServiceHead
        }
      }
    }
  });

  //SUBVENTION PROJECTS

  can("update", "subvention_projects", "all", {
    "dossier.service_id": {
      $in: serviceIdsAsServiceHead
    },
    step: SUBVENTION_PROJECT_STEP.SERVICE_HEAD_VALIDATION
  });
  can(["read", "update"], "subvention_projects", "step", {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    "dossier.service_id": {
      $in: serviceIdsAsServiceHead
    },
    step: SUBVENTION_PROJECT_STEP.SERVICE_HEAD_VALIDATION
  });
  can("create", "subvention_projects", "validations", {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    "dossier.service_id": {
      $in: serviceIdsAsServiceHead
    },
    step: SUBVENTION_PROJECT_STEP.SERVICE_HEAD_VALIDATION
  });

  // PIA database
  can("read", "PIA-database");

  //LIQUIDATION
  can("read", "liquidations", "validations");
  can("create", "liquidations", "validations", {
    step: {
      $eq: LIQUIDATION_STEP.SERVICE_HEAD_VALIDATION
    }
  });

  // PDF DOCUMENTS
  can("read", "pdf_documents");
};